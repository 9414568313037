const TIME_BETWEEN_FRAMES = 1500;
const FRAME_DURATION = 1;
const TOTAL_EPISODES = 3;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const lang = window.location.pathname.indexOf('/en') === -1 ? 'hu' : 'en';

function createCookie(name, value, days) {
    let expires;
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    } else {
        expires = '';
    }
    document.cookie = `${name}=${value}${expires};path=/`;
}

function getCookie(name) {
    if (document.cookie.length > 0) {
        let start = document.cookie.indexOf(`${name}=`);
        if (start !== -1) {
            start = start + name.length + 1;
            let end = document.cookie.indexOf(';', start);
            if (end === -1) {
                end = document.cookie.length;
            }
            return unescape(document.cookie.substring(start, end));
        }
    }
    return '';
}

// Load iframe or pictures into the frames based on the window size
const loadIframe = (season, episode, id) => {
    if (window.innerWidth < 576) {
        $(`#frame${id}`).css(
            'background-image',
            `url('/assets/img/s${season}/e${episode}_${lang.toLowerCase()}/${id}.png')`
        );
        $(`#frame${id}`).html('');
    } else {
        $(`#frame${id}`).html(
            `<iframe src="/assets/animations/s${season}/e${episode}/f${id}/index_${lang.toLowerCase()}.html" frameborder="0" scrolling="no" title="DCU - DONE Patrol S${season}E${episode} - ${id}"></iframe>`
        );
    }
};

// Animate given frame to look like it's sliding in from the bottom
const fromAnimation = frame => {
    gsap.from(`#frame${frame}`, {
        duration: FRAME_DURATION,
        yPercent: +50
    });
};

(function($) {
    if ($('.episode').length) {
        $(() => {
            // Initialize episode picker slider
            /* $('#ep__carousel').slick({
                vertical: true,
                verticalSwiping: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                prevArrow: $('#prev__ep'),
                nextArrow: $('#next__ep'),
                initialSlide: 6
            }); */

            document.getElementById('picker__toggle').addEventListener('click', function() {
                this.style.display = 'none';
                document.getElementById('picker').style.display = 'block';
            });

            document.getElementById('close__picker').addEventListener('click', function() {
                document.getElementById('picker').style.display = 'none';
                document.getElementById('picker__toggle').style.display = 'flex';
            });

            let animating = false;
            let animationDone = false;
            let currentFrame = 1;
            const epContainer = document.querySelector('.episode');
            const { season, episode } = epContainer.dataset;
            document.getElementById(`e${episode}_link`).classList.add('active');
            const frameCount = document.querySelectorAll('.frame').length;
            if (episode > 1) {
                document.getElementById('prevEPbtn').href = `${
                    lang === 'en' ? '/en' : ''
                }/episodeS${season}E${parseInt(episode, 10) - 1}`;
            } else {
                document.getElementById('prevEPbtn').classList.add('disabled');
            }
            if (TOTAL_EPISODES > episode) {
                document.getElementById('nextEPbtn').href = `${
                    lang === 'en' ? '/en' : ''
                }/episodeS${season}E${parseInt(episode, 10) + 1}`;
            } else {
                document.getElementById('nextEPbtn').classList.add('disabled');
            }

            // Prevent page from being scrolled
            document.body.style.overflow = 'hidden';

            // Initially scroll to the top and reset frames container into the top
            window.scrollTo(0, 0);

            let alreadySeen = false;

            if (getCookie('seenEpisodes')) {
                const currentlySeen = JSON.parse(getCookie('seenEpisodes'));
                if (currentlySeen.includes(`S${season}E${episode}`)) {
                    alreadySeen = true;
                    $('#episode__navigator').removeClass('hidden');
                } else {
                    currentlySeen.push(`S${season}E${episode}`);
                    createCookie('seenEpisodes', JSON.stringify(currentlySeen), 1);
                    alreadySeen = false;
                }
            } else {
                createCookie('seenEpisodes', JSON.stringify([`S${season}E${episode}`]), 1);
                alreadySeen = false;
            }

            $('#rewatch').click(() => {
                const episodes = JSON.parse(getCookie('seenEpisodes'));
                episodes.splice(episodes.indexOf(`S${season}E${episode}`), 1);
                console.log(episodes);
                createCookie('seenEpisodes', JSON.stringify(episodes), 1);
                window.location.reload();
            });

            // get frame id a row (needed bc there can be rows with multiple frames)
            const getSubFrameId = (row, id) => {
                let index = row.children.length;
                let child = row.children[row.children.length - 1];
                while (child !== null) {
                    index--;
                    if (child.id === `frame${id}`) {
                        return index;
                    }
                    child = child.previousSibling.previousSibling;
                }
                return index;
            };

            // Animate the frames in whole to create the illusion of the page being scrolled
            const proceedPage = (id = 0) => {
                if (window.innerWidth < 576) {
                    gsap.to('.frames', {
                        duration: FRAME_DURATION,
                        y: `-=${
                            document
                                .getElementById(`frame${id - 1}`)
                                .classList.contains('comicStart')
                                ? document.getElementById(`frame${id}`).offsetHeight +
                                  16 -
                                  document.getElementById(`frame${id - 1}`).offsetHeight
                                : document.getElementById(`frame${id}`).offsetHeight + 16
                        }` // + 16 is needed for 1rem margin bottom
                    });
                } else {
                    gsap.to('.frames', {
                        duration: FRAME_DURATION,
                        y: `-=${document.getElementById(`frame${id}`).offsetHeight + 16}` // + 16 is needed for 1rem margin bottom
                    });
                }
            };

            /*
             * animator function for rows with one frame only
             * Params:
             *  frameId: int - Id of the frame globally
             *  proceed: boolean - if the page should proceed with the animation
             */
            const animateF1 = (row, frameId, proceed) => {
                if (proceed) {
                    proceedPage(frameId);
                }
                fromAnimation(frameId);
                gsap.to(`#frame${frameId}`, {
                    duration: FRAME_DURATION,
                    opacity: 1
                });
            };

            /*
             * animator function for rows with two frames
             * Params:
             *  frameId: int - Id of the frame globally
             *  subframe: int - Number of the frame in its row
             *  proceed: boolean - if the page should proceed with the animation
             */
            const animateF2 = (row, frameId, subframe, proceed, isReversed) => {
                if (window.innerWidth > 576) {
                    switch (subframe) {
                        // animate 1st frame
                        case 0:
                            if (proceed) {
                                proceedPage(frameId);
                            }
                            fromAnimation(frameId);
                            gsap.to(`#frame${frameId}`, {
                                duration: 0,
                                xPercent: isReversed ? 25 : 50
                            });
                            gsap.to(`#frame${frameId}`, {
                                duration: FRAME_DURATION,
                                opacity: 1
                            });
                            break;
                        // animate 2nd frame
                        case 1:
                            fromAnimation(frameId);
                            gsap.to(`#frame${frameId - 1}`, {
                                duration: FRAME_DURATION,
                                xPercent: 0
                            });
                            gsap.to(`#frame${frameId}`, {
                                duration: FRAME_DURATION,
                                opacity: 1
                            });
                            break;
                        default:
                            break;
                    }
                } else {
                    switch (subframe) {
                        case 0:
                        case 1:
                            if (proceed) {
                                proceedPage(frameId);
                            }
                            fromAnimation(frameId);
                            gsap.to(`#frame${frameId}`, {
                                duration: FRAME_DURATION,
                                opacity: 1
                            });
                            break;
                        default:
                            break;
                    }
                }
            };

            /*
             * animator function for rows with three frames
             * Params:
             *  frameId: int - Id of the frame globally
             *  subframe: int - Number of the frame in its row
             *  proceed: boolean - if the page should proceed with the animation
             */
            const animateF3 = (row, frameId, subframe, proceed) => {
                if (window.innerWidth > 576) {
                    switch (subframe) {
                        case 0:
                            // animate 1st frame
                            if (proceed) {
                                proceedPage(frameId);
                            }
                            fromAnimation(frameId);
                            gsap.to(`#frame${frameId}`, {
                                duration: 0,
                                xPercent: 100
                            });
                            gsap.to(`#frame${frameId}`, {
                                duration: FRAME_DURATION,
                                opacity: 1
                            });
                            break;
                        case 1:
                            // animate 2nd frame
                            fromAnimation(frameId);
                            gsap.to(`#frame${frameId - 1}`, {
                                duration: FRAME_DURATION,
                                xPercent: 50
                            });
                            gsap.to(`#frame${frameId}`, {
                                duration: 0,
                                xPercent: 50
                            });
                            gsap.to(`#frame${frameId}`, {
                                duration: FRAME_DURATION,
                                opacity: 1
                            });
                            break;
                        case 2:
                            // animate 3rd frame
                            fromAnimation(frameId);
                            gsap.to(`#frame${frameId - 2}`, {
                                duration: FRAME_DURATION,
                                xPercent: 0
                            });
                            gsap.to(`#frame${frameId - 1}`, {
                                duration: FRAME_DURATION,
                                xPercent: 0
                            });
                            gsap.to(`#frame${frameId}`, {
                                duration: FRAME_DURATION,
                                opacity: 1
                            });
                            break;
                        default:
                            break;
                    }
                } else {
                    // on mobile animate all frames in the same way
                    switch (subframe) {
                        case 0:
                        case 1:
                        case 2:
                            // animate 1st frame
                            if (proceed) {
                                proceedPage(frameId);
                            }
                            fromAnimation(frameId);
                            gsap.to(`#frame${frameId}`, {
                                duration: FRAME_DURATION,
                                opacity: 1
                            });
                            break;
                        default:
                            break;
                    }
                }
            };

            // Lock animation triggering for the time period of the frame animations
            const changeAnimating = () => {
                setTimeout(() => {
                    animating = false;
                }, TIME_BETWEEN_FRAMES);
            };

            /*
             *  Animate rows
             *  Params:
             *    row: HTML element - the row
             *    frameType: string - 'f1', 'f2', 'f3' is defined
             *    frameId: int - id of the frame that needs to be animated
             *    proceed: boolean - if the page should proceed with the animation, default true
             */
            const animateRow = ({ row, frameType, frameId, isReversed }, proceed = true) => {
                const subframe = getSubFrameId(row, frameId);
                loadIframe(season, episode, frameId);
                // SetTimeout needed for delayed displaying of iframe, to not make it glitchy
                setTimeout(() => {
                    switch (frameType) {
                        case 'f1':
                            animateF1(row, frameId, proceed);
                            break;
                        case 'f2':
                            animateF2(row, frameId, subframe, proceed, isReversed);
                            break;
                        case 'f3':
                            animateF3(row, frameId, subframe, proceed);
                            break;
                        default:
                            console.log('frameType data attribute not provided');
                            break;
                    }
                }, 400);
            };

            // Get the frame, frametype and row of the frame that needs to be animated, by frame id
            const getAnimatedFrame = frameId => {
                const frameElement = document.getElementById(`frame${frameId}`);
                const row = frameElement.parentElement;
                const frameType = frameElement.dataset.frametype;
                let isReversed = false;

                if (frameElement.classList.contains('f3-2_reverse')) {
                    isReversed = true;
                }
                return { row, frameType, frameId, isReversed };
            };

            document.addEventListener('keydown', e => {
                if (!animating && e.key === 'ArrowDown' && !animationDone) {
                    animating = true;
                    window.dispatchEvent(
                        new CustomEvent('animate', {
                            detail: {
                                frame: currentFrame
                            }
                        })
                    );
                    currentFrame += 1;
                    changeAnimating();
                }
            });

            // here

            const revealFrame = frame => {
                $(frame).css({ opacity: 1 });
            };

            if (alreadySeen) {
                for (let f_index = 1; f_index <= frameCount; f_index++) {
                    loadIframe(season, episode, f_index);
                    revealFrame($(`#frame${f_index}`));
                }
                $('.frames').css('top', '15vh');
                if (window.innerWidth < 576) {
                    gsap.to('.frames', {
                        backgroundColor: 'white',
                        border: '5px solid black',
                        duration: 1
                    });
                } else {
                    gsap.to(
                        '.frames',
                        {
                            backgroundColor: 'white',
                            border: '5px solid black',
                            duration: 1
                        },
                        1
                    );
                }
                document.getElementById('frame__next').style.display = 'none';
                document.body.style.overflow = 'auto';
            } else {
                window.addEventListener('wheel', e => {
                    if (!animating && e.deltaY > 0 && !animationDone) {
                        animating = true;
                        window.dispatchEvent(
                            new CustomEvent('animate', {
                                detail: {
                                    frame: currentFrame
                                }
                            })
                        );
                        currentFrame += 1;
                        changeAnimating();
                    }
                });

                let ts = 0;
                window.addEventListener('touchstart', e => {
                    ts = e.touches[0].screenY;
                });

                window.addEventListener('touchmove', e => {
                    e.preventDefault();
                    if (!animating && e.touches[0].clientY < ts + 5 && !animationDone) {
                        animating = true;
                        window.dispatchEvent(
                            new CustomEvent('animate', {
                                detail: {
                                    frame: currentFrame
                                }
                            })
                        );
                        currentFrame += 1;
                        changeAnimating();
                    }
                });

                animateRow(getAnimatedFrame(1), false);
                setTimeout(() => {
                    window.scrollTo(0, 0);
                    currentFrame = 2;
                }, TIME_BETWEEN_FRAMES);

                window.addEventListener('animate', e => {
                    if (e.detail.frame <= frameCount) {
                        if (e.detail.frame !== 1) {
                            const frameData = getAnimatedFrame(e.detail.frame);
                            animateRow(frameData);
                        }
                    } else {
                        animationDone = true;
                        gsap.to('.frame', {
                            duration: 1,
                            y: 0
                        });
                        gsap.to('.frames', {
                            duration: 3,
                            yPercent: 5
                        });
                        gsap.to('.frames', {
                            backgroundColor: 'white',
                            border: '5px solid black',
                            duration: 1
                        });
                        $('.episode').css('padding', '7rem 0 12rem 0');
                        if (window.innerWidth < 576) {
                            $('.frames').css('top', '15vh');
                        }

                        document.getElementById('frame__next').style.display = 'none';
                        $('#episode__navigator').removeClass('hidden');
                        if (TOTAL_EPISODES > episode) {
                            /* document.getElementById('nextEPbtn').classList.remove('hidden'); */
                        }

                        gsap.to(`.frames`, {
                            duration: FRAME_DURATION,
                            y: '-25vh'
                        });
                        // enable scrolling after whole page is visible, multiplying is needed bc setTimeout needs ms.
                        setTimeout(() => {
                            document.body.style.overflow = 'auto';
                        }, FRAME_DURATION * 1000 + 500);
                    }
                });

                document.getElementById('frame__next').addEventListener('mouseover', function() {
                    $(this).css('animation', 'none');
                });

                document.getElementById('frame__next').addEventListener('click', () => {
                    if (!animating && !animationDone) {
                        animating = true;
                        window.dispatchEvent(
                            new CustomEvent('animate', {
                                detail: {
                                    frame: currentFrame
                                }
                            })
                        );
                        currentFrame += 1;
                        changeAnimating();
                    }
                });
            }
        });
    }
})(jQuery);
