const LOGO_ANIM_DURATION = 10;

const lang = window.location.pathname.indexOf('/en') === -1 ? 'hu' : 'en';

(function($) {
    if ($('.main').length) {
        if (document.referrer.indexOf(document.location.hostname) !== -1) {
            $('#three-container').css('display', 'none');

            gsap.to(
                '.animateIn',
                {
                    duration: 2,
                    opacity: 1
                },
                0.5
            );

            gsap.to(
                '.logoAnimBackground',
                {
                    duration: 2,
                    opacity: 0
                },
                0.5
            );

            gsap.to(
                '.logoAnimBackground',
                {
                    duration: 0,
                    display: 'none'
                },
                2.5
            );
        } else {
            $(() => {
                gsap.to(
                    '#three-container',
                    {
                        duration: 1,
                        opacity: 0,
                        display: 'none'
                    },
                    LOGO_ANIM_DURATION
                );

                gsap.to(
                    '.animateIn',
                    {
                        duration: 2,
                        opacity: 1
                    },
                    LOGO_ANIM_DURATION + 0.5
                );

                gsap.to(
                    '.logoAnimBackground',
                    {
                        duration: 2,
                        opacity: 0
                    },
                    LOGO_ANIM_DURATION + 0.5
                );

                gsap.to(
                    '.logoAnimBackground',
                    {
                        duration: 0,
                        display: 'none'
                    },
                    LOGO_ANIM_DURATION + 2.5
                );
            });
        }

        if (document.cookie.includes('videoshown')) {
            $('#three-container').css('display', 'none');

            gsap.to(
                '.animateIn',
                {
                    duration: 2,
                    opacity: 1
                },
                0.5
            );

            gsap.to(
                '.logoAnimBackground',
                {
                    duration: 2,
                    opacity: 0
                },
                0.5
            );

            gsap.to(
                '.logoAnimBackground',
                {
                    duration: 0,
                    display: 'none'
                },
                2.5
            );
        } else {
            document.cookie = `videoshown=ok; max-age=43200`;

            if (window.innerWidth < 576) {
                let lastGamma = 0;
                window.addEventListener('deviceorientation', e => {
                    if (Math.abs(lastGamma - e.gamma) > 5) {
                        const x = (e.gamma.toFixed(2) + 180) * 0.3;
                        $('.bg__primary').css('transform', `scale(1.3) rotate(${-x}deg)`);
                        $('.bg__secondary').css('transform', `scale(1.6) rotate(${x}deg)`);
                        lastGamma = e.gamma;
                    }
                });
            } else {
                window.addEventListener('mousemove', e => {
                    $('.bg__primary').css('transform', `rotate(${e.clientX * -0.01}deg)`);
                    $('.bg__secondary').css(
                        'transform',
                        `scale(1.3) rotate(${e.clientX * 0.01}deg)`
                    );
                });
            }

            $('#episodeSelect').select2({
                dropdownCssClass: 'episode_select',
                dropdownAutoWidth: true
            });

            $('.select2-selection__arrow').html('<i class="far fa-chevron-down"></i>');

            if (document.getElementById('jumpToEpisode')) {
                document.getElementById('jumpToEpisode').addEventListener('click', () => {
                    const selected = $('#episodeSelect').select2('data')[0].id;
                    window.location.href = `${lang === 'en' ? '/en' : ''}/episodeS1E${selected}`;
                });
            }
        }
    }
})(jQuery);
