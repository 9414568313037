const DONEGDPRParams = {
    color: '#F63907',
    lang: 'en',
    policy_url: 'https://thisisdone.com',
    groups: {
        always_active: [
            {
                name: 'Page Cookie Consent'
            },
            {
                name:
                    'Google (SEARCH_SAMESITE, S, NID, ANID, CONSENT, P_JAR, HSID, APISID, SAPISID, SID, SIDCC, SSID)'
            }
        ],
        performance: [
            {
                name: 'Google'
            }
        ],
        targeting: [
            {
                name: 'Google Analytics'
            }
        ]
    }
};

(function($) {
    const gtm = 'GTM-TDRHCP5';

    $(() => {
        $.cookieSettings = Cookies.get('DONEGDPRConsent');

        if ($.cookieSettings != undefined) {
            $.cookieSettings = JSON.parse($.cookieSettings);
        } else {
            $('.done-gdpr-alert-box-wrapper').addClass('active');
        }

        if ($.cookieSettings != undefined && $.cookieSettings.targeting == true) {
            window[`ga-disable-${gtm}`] = false;

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            });
        } else {
            window[`ga-disable-${gtm}`] = true;

            const cookies = Cookies.get();

            if (cookies !== '') {
                $.each(cookies, index => {
                    index = `${index}`;

                    if (index === '_ga' || index === '_gid' || index === '_gat') {
                        Cookies.remove(index, { domain: document.domain });
                    }
                });
            }
        }
        $(document).on('click', '.js-open-cookie a', e => {
            e.preventDefault();
            cookiedone.showCookieSettings();

            if ($.cookieSettings != undefined && $.cookieSettings.performance == true) {
                $('.done-gdpr-status-checkbox.performance').attr('checked', true);
            }
            if ($.cookieSettings != undefined && $.cookieSettings.targeting == true) {
                $('.done-gdpr-status-checkbox.targeting').attr('checked', true);
            }
        });

        $(document).on('click', '#done-gdpr-save-button', e => {
            location.reload();
        });

        $(document).on('click', '.done-gdpr-button-allow', e => {
            e.preventDefault();
            e.stopPropagation();
            cookiedone.allowAll();
            location.reload();
        });
    });
})(jQuery);
