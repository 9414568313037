(function($) {
    if ($('.sidebar')) {
        $('#trigger__backstory').click(function() {
            $('.backstory').toggleClass('hidden');
            $('#backstory__icon').toggleClass('rotateIcon');
        });

        const toggleSidebar = () => {
            if ($('.sidebar').hasClass('hidden')) {
                $('.sidebar').css('display', 'block');
                setTimeout(() => {
                    $('.sidebar').removeClass('hidden');
                }, 300);
            } else {
                $('.sidebar').addClass('hidden');
                setTimeout(() => {
                    $('.sidebar').css('display', 'none');
                }, 300);
            }
        };

        $('.sidebar .content').click(function(e) {
            e.stopPropagation();
        });

        $('.sidebar').click(function() {
            const sb = this;
            $(sb).addClass('hidden');
            setTimeout(() => {
                $(sb).css('display', 'none');
            }, 300);
        });

        $('#trigger__sidebarclose').click(toggleSidebar);
        $('#trigger__sibaropen').click(toggleSidebar);
    }
})(jQuery);
